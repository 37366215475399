<template>
  <v-container>
    <v-snackbar
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="addUserDialog" width="600">
      <v-card elevation="8">
        <v-card-title>Nuovo utente locale</v-card-title>
        <v-card-text>
          <v-form v-model="isFormValid">
            <v-row>
              <v-col cols="4" class="text-right" align-self="start"
                >MATRICOLA:</v-col
              >
              <v-col cols="8" align-self="start">
                <v-text-field
                  v-model="body.MATRICOLA"
                  label="MATRICOLA"
                  :rules="matricolaRules"
                  hint="opzionale - se assente, sarà generata automaticamente"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-right" align-self="start"
                >COGNOME:</v-col
              >
              <v-col cols="8" align-self="start">
                <v-text-field
                  v-model="body.COGNOME"
                  label="COGNOME"
                  :rules="[() => !!body.COGNOME || 'il COGNOME è obbligatorio']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-right" align-self="start"
                >NOME:</v-col
              >
              <v-col cols="8" align-self="start">
                <v-text-field
                  v-model="body.NOME"
                  label="NOME"
                  :rules="[() => !!body.NOME || 'il NOME è obbligatorio']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-right" align-self="start"
                >EMAIL:</v-col
              >
              <v-col cols="8" align-self="start">
                <v-text-field
                  v-model="body.EMAIL"
                  label="EMAIL"
                  :rules="emailRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-right" align-self="start"
                >TIPO:</v-col
              >
              <v-col cols="8" align-self="start">
                <v-select
                  :items="userTypes"
                  item-text="DS_RUOLO"
                  return-object
                  v-model="userType"
                  required
                  :rules="[(v) => !!v || 'Il tipo è obbligatorio']"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="addUser()" :disabled="!isFormValid">OK</v-btn>
          <v-btn @click="addUserDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="12" min-height="600">
      <v-row>
        <v-col cols="6">
          <v-card-title>Persone</v-card-title>
        </v-col>
        <v-col class="pr-8" cols="6" align="right" align-self="center">
          <v-btn
            title="Figura virtuale che gestisce i beni non assegnati "
            elevation="2"
            to="users/-6589"
            >Direttore</v-btn
          >
          <v-btn v-if="userRole === 'admin'"
            title="Nuovo utente locale"
            elevation="2"
            @click="addUserDialog = true"
            >Aggiungi</v-btn
          >
        </v-col>
      </v-row>

      <v-card-text>
        <v-row v-show="loading" justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
        <v-data-table
          v-show="!loading"
          elevation="10"
          :headers="headers"
          :items="users"
          item-key="MATRICOLA"
          :search="search"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-minus',
            nextIcon: 'mdi-plus',
            itemsPerPageText: 'Utenti per pagina',
            itemsPerPageAllText: 'Tutti gli utenti',
            itemsPerPageOptions: [10, 25, 50, 100, 500, -1],
            pageText: '',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Utenti per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet> Nessun utente </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet> Nessun utente </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :to="'/users/' + item.MATRICOLA"
                >
                  <v-icon small> mdi-eye-outline </v-icon>
                </v-btn>
              </template>
              <span>Visualizza</span>
            </v-tooltip>
          </template>
          <template v-slot:item.COGNOME="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.COGNOME
            }}</span>
          </template>
          <template v-slot:item.NOME="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.NOME
            }}</span>
          </template>
          <template v-slot:item.MATRICOLA="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.MATRICOLA
            }}</span>
          </template>
          <template v-slot:item.EMAIL="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.EMAIL
            }}</span>
          </template>
          <template v-slot:item.DS_RUOLO="{ item }">
            <span :class="item.STATO === 'attivo' ? '' : 'text--disabled'">{{
              item.DS_RUOLO
            }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

//const axios = require('axios');

import { mapState } from "vuex";

export default {
  name: "Users",
  data: () => {
    return {
      userTypes: [
        {
          TIPO_ORGANICO: "GUEST",
          DESCRIZIONE_TIPO_ORGANICO: "Guest/Visiting/Tesista",
          RUOLO: "GU",
          DS_RUOLO: "Guest/Visiting/Tesista",
        },
        {
          TIPO_ORGANICO: "STUDENT",
          DESCRIZIONE_TIPO_ORGANICO: "Student",
          RUOLO: "ST",
          DS_RUOLO: "Student",
        },
        {
          TIPO_ORGANICO: "EXTTEACHER",
          DESCRIZIONE_TIPO_ORGANICO: "External teacher",
          RUOLO: "GU",
          DS_RUOLO: "External teacher",
        },
      ],
      userType: null,
      isFormValid: false,
      users: [],
      search: "",
      loading: false,
      addUserDialog: false,
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: -1,
      body: {
        MATRICOLA: "",
        COGNOME: "",
        NOME: "",
        TIPO_ORGANICO: "",
        DESCRIZIONE_TIPO_ORGANICO: "",
        RUOLO: "",
        DS_RUOLO: "",
        EMAIL: "",
      },
      emailRules: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail valida richiesta",
      ],
      matricolaRules: [
        (v) =>
          v === '' ||
          (Number.isInteger(parseInt(v)) && parseInt(v) == v) ||
          "Richiesto un numero o campo vuoto",
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Matricola",
          align: "end",
          sortable: false,
          value: "MATRICOLA",
        },
        {
          text: "Cognome",
          align: "start",
          sortable: true,
          value: "COGNOME",
        },
        {
          text: "Nome",
          align: "start",
          sortable: true,
          value: "NOME",
        },
        {
          text: "e-mail",
          align: "start",
          sortable: false,
          value: "EMAIL",
        },
        {
          text: "Ruolo",
          align: "start",
          sortable: false,
          value: "DS_RUOLO",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "id",
        },
      ];
    },
    ...mapState(["userInitials", "userRole", "userPrivacy"]),
  },

  created: function () {
    this.loading = true;
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/list", {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          this.users = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    addUser() {
      for (const [key, value] of Object.entries(this.userType)) {
        this.body[key] = value;
      }
      this.$http
        .post(process.env.VUE_APP_PATRIMONIO_API + "/users", this.body, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then(() => {
          this.errorText = "Utente creato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.loading = false;
          this.addUserDialog = false;
          this.loadUsers();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.loading = false;
        });
    },
  },
};
</script>